import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"

import AwardCategoryShortlist from "../components/award-category-shortlist"
import ShortlistOrganisation from "../components/shortlist-organisation"
import PrimaryButton from "../components/primary-button"

const WinnersPage = () => (
  <Background>
    <Layout>
      <Seo title="Winners" />
      <Container>
        <PageTitle text="Winners" />
        <p style={{
          textTransform: `uppercase`,
          color: `#fff`,
          fontWeight: `bold`,
          textAlign: `center`,
          maxWidth: `750px`,
          margin: `auto`,
          marginBottom: `50px`,
        }} hidden>Congratulations to all organisations who have made it to the shortlist.</p>

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Improving Spaces" subtitle="Creating a Great Place to Work" gridColor="#d23464" icon="computer-desk">
              <ShortlistOrganisation organisation="HMP Birmingham in partnership with Aramark" project="Two Projects for Improving Spaces for Residents and Staff" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
              <ShortlistOrganisation organisation="Kent County Council" project="Kent Connects and Kent Estates Shared Workspace" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Innovation as a Service" gridColor="#30c4bf" icon="innovation">
              <ShortlistOrganisation organisation="West Berkshire Council" project="Walnut Close" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Digital Innovation as a Service" gridColor="#30c4bf" icon="digital-innovation">
              <ShortlistOrganisation organisation="Royal Air Force (Astra Team)" project="Project BLUEPRINT" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
              <ShortlistOrganisation organisation="University Hospital Dorset" project="Intelligent Workflows" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Customer Experience" gridColor="#30c4bf" icon="customer-experience">
              <ShortlistOrganisation organisation="London Borough of Barking & Dagenham Council" project="Supporting Vulnerable Customers, Promoting Agent Wellbeing" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="User Experience" gridColor="#d23464" icon="user-experience">
              <ShortlistOrganisation organisation="Environment Agency" project="Nexus Enforcement" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Smarter Workflows" gridColor="#30c4bf" icon="workflows">
              <ShortlistOrganisation organisation="BT & Soprano Design with the NHS" project="Conversational AI to Reduce Did Not Attends & Streamline Clinical Pathways" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Digital Inclusion" gridColor="#d23464" icon="inclusion">
              <ShortlistOrganisation organisation="Norfolk County Council" project="Tech Skills for Life" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Automation, Artifical Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
              <ShortlistOrganisation organisation="University Hospitals Coventry and Warwickshire NHS Trust" project="Transforming People Services through digital innovation and automation" />
            </AwardCategoryShortlist>
          </div>
        </Row>
        
        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
              <ShortlistOrganisation organisation="Department Business and Trade" project="Darlington Economic Campus" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
              <ShortlistOrganisation organisation="Buckinghamshire and Somerset Councils" project="How Copilot 365 I&C Sub-Group Built a Collaborative Community of Practice" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Putting People First" gridColor="#d23464" icon="inspire">
              <ShortlistOrganisation organisation="Nexus" project="Our People" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
              <ShortlistOrganisation organisation="Department for Work and Pensions" project="Move to Universal Credit" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
              <ShortlistOrganisation organisation="HM Revenue and Customs" project="Transforming the Way We Work Programme" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <h2 className="my-5" style={{
          color: `#fff`,
          fontFamily: `Avenir`,
          fontWeight: `900`,
          fontSize: `2.5rem`,
          textAlign: `center`,
          margin: `3rem 0`
        }}>Judges Choice</h2>

        <Row>
          <div className="col-lg">
            <AwardCategoryShortlist title="Special Recognition" gridColor="#d23464" icon="trophy">
              <ShortlistOrganisation organisation="Harrogate and District NHS Foundation Trust" />
            </AwardCategoryShortlist>
          </div>
          <div className="col-lg">
            <AwardCategoryShortlist title="Beyond Smarter Working" gridColor="#d23464" icon="star">
              <ShortlistOrganisation organisation="West Berkshire Council" />
            </AwardCategoryShortlist>
          </div>
        </Row>

        <br /><br />
        
        <div className="text-center">
          <PrimaryButton to="/shortlist/" text="View the Shortlist" />
        </div>

        <br /><br /><br /><br />

      </Container>
    </Layout>
  </Background>
)

export default WinnersPage
